import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import CustomCursor from './CustomCursor';
import Bairon from './Bairon';
import Projects from './Projects';
import LoadingScreen from './LoadingScreen';
import './App.css';

function Overlay() {
  const location = useLocation();

  if (location.pathname === '/') {
    return <div className="name-overlay">BAIRON&nbsp;RECINOS</div>;
  }
  return null;
}

function ResumeRedirect() {
  window.location.href = '/Resume.pdf';
  return null;
}

function NotFound() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center'
    }}>
      <h1>404 Not Found</h1>
      <p>The requested URL was not found on this server.</p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <LoadingScreen />
        <CustomCursor />
        <nav className="nav-bar">
          <Link to="/">ABOUT</Link>
          <a href="https://github.com/baironrecinos" target="_blank" rel="noopener noreferrer">PROJECTS</a>
          <a href="/Resume.pdf" target="_blank" rel="noopener noreferrer">RESUMÉ</a>
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<Bairon />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/resume" element={<ResumeRedirect />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Overlay />
        <footer className="footer">
          <a href="https://www.linkedin.com/in/baironrecinos/" target="_blank" rel="noopener noreferrer">LINKEDIN</a>
          <a href="https://github.com/baironrecinos" target="_blank" rel="noopener noreferrer">GITHUB</a>
        </footer>
      </div>
    </Router>
  );
}

export default App;
