import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function ProjectModel({ position, rotation, index, isSelected, setSelected }) {
  const { scene } = useGLTF('/assets/projects.glb');
  const ref = useRef();
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const clone = scene.clone();
    ref.current.add(clone);
    ref.current.position.set(...position);
    ref.current.rotation.set(...rotation);
  }, [position, rotation, scene]);

  useFrame(() => {
    if (hovered || isSelected) {
      ref.current.position.lerp(new THREE.Vector3(...position).multiplyScalar(0.9), 0.1);
    } else {
      ref.current.position.lerp(new THREE.Vector3(...position), 0.1);
    }
  });

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent the click from propagating to the document
    setHovered((prev) => !prev);
    setSelected(index); // Set the current model as selected
  };

  return (
    <group
      ref={ref}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onClick={handleClick}
    />
  );
}

function RotatingProjects({ radius, rotationSpeed }) {
  const steps = 10;
  const angleStep = (2 * Math.PI) / steps;
  const [selected, setSelected] = useState(null);

  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += rotationSpeed;
    }
  });

  const projectPositions = Array.from({ length: steps }, (_, i) => {
    const angle = i * angleStep;
    return {
      position: [Math.cos(angle) * radius, 0, Math.sin(angle) * radius],
      rotation: [0, -angle, 0],
    };
  });

  useEffect(() => {
    const handleClickOutside = () => {
      setSelected(null); // Deselect the model if clicked outside
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <group ref={groupRef} onClick={(e) => e.stopPropagation()}>
      {projectPositions.map((pos, index) => (
        <ProjectModel
          key={index}
          position={pos.position}
          rotation={pos.rotation}
          index={index}
          isSelected={selected === index}
          setSelected={setSelected}
        />
      ))}
    </group>
  );
}

function Projects() {
  const defaultRadius = 4.75;
  const defaultRotationSpeed = 0.001;
  const [radius, setRadius] = useState(defaultRadius);
  const [rotationSpeed, setRotationSpeed] = useState(defaultRotationSpeed);
  const cameraOffset = 0.5;
  const [fov, setFov] = useState(60);

  const cameraPosition = [
    Math.cos(Math.PI) * (radius - cameraOffset), 
    0, 
    Math.sin(Math.PI) * (radius - cameraOffset)
  ];
  const targetPosition = [Math.cos(0) * radius, 0, Math.sin(0) * radius];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setFov(100); // Increase FOV for mobile screens
        setRadius(4.5); // Reduce radius for mobile screens
        setRotationSpeed(0.002); // Increase rotation speed for mobile screens
      } else {
        setFov(70); // Default FOV for larger screens
        setRadius(defaultRadius); // Default radius for larger screens
        setRotationSpeed(defaultRotationSpeed); // Default rotation speed for larger screens
      }
    };

    handleResize(); // Set initial FOV, radius, and rotation speed
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    document.querySelector('canvas').addEventListener('wheel', handleScroll);
    return () => {
      document.querySelector('canvas').removeEventListener('wheel', handleScroll);
    };
  }, []);

  useEffect(() => {
    const canvas = document.querySelector('canvas');
    
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        canvas.style.width = '100%';
        canvas.style.height = '100%';
      } else {
        canvas.style.width = '100vw';
        canvas.style.height = '100vh';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Canvas
      style={{ height: '100vh', width: '100vw' }}
      camera={{ position: cameraPosition, fov: fov }}
    >
      <Suspense fallback={null}>
        <RotatingProjects radius={radius} rotationSpeed={rotationSpeed} />
      </Suspense>
      <OrbitControls 
        enableZoom={false} 
        enablePan={false} 
        enableRotate={false} 
        target={targetPosition} 
        enableDamping={false}
      />
    </Canvas>
  );
}

export default Projects;
