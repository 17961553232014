import React, { useEffect } from 'react';
import './LoadingScreen.css'; // Assuming you have styles for the loading screen

const LoadingScreen = () => {
  useEffect(() => {
    const loadingScreen = document.getElementById('loadingScreen');
    const numTiles = 3;

    const createAndAnimateTiles = () => {
      loadingScreen.innerHTML = ''; // Clear existing tiles

      // Create tiles for the loading animation
      for (let i = 0; i < numTiles; i++) {
        const tile = document.createElement('div');
        tile.className = 'tile';
        if (i % 2 === 0) {
          tile.classList.add('even');
        } else {
          tile.classList.add('odd');
        }
        loadingScreen.appendChild(tile);
      }

      setTimeout(() => {
        const tiles = document.querySelectorAll('.tile');
        tiles.forEach((tile, index) => {
          setTimeout(() => {
            // Customize the initial loading screen animation here
            if (tile.classList.contains('odd')) {
              tile.classList.add('drop-bottom'); // Customize collapse direction for odd tiles
            } else {
              tile.classList.add('drop-top'); // Customize collapse direction for even tiles
            }
          }, index * 200); // Customize the delay between each tile's animation
        });

        // Hide the loading screen after the animation completes
        setTimeout(() => {
          if (loadingScreen) {
            loadingScreen.style.display = 'none';
          }
        }, tiles.length * 200 + 1000); // Customize the timing to match the end of the animation
      }, 1000); // Initial delay of 1 second
    };

    // Trigger the initial animation when the component mounts
    createAndAnimateTiles();

  }, []);

  return <div id="loadingScreen" />;
};

export default LoadingScreen;