import React, { Suspense, useRef, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

function Model() {
  const { scene } = useGLTF('/assets/bairon.glb');
  const ref = useRef();

  useFrame((state, delta) => {
    ref.current.rotation.y = Math.sin(state.clock.getElapsedTime()) * 0.25;
  });

  return <primitive ref={ref} object={scene} position={[0, 0, 0]} />;
}

function Bairon() {
  const [cameraPosition, setCameraPosition] = useState([0, 0, 3]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCameraPosition([0, 0, 5.25]); // Adjust the camera position for smaller screens
      } else {
        setCameraPosition([0, 0, 3]); // Default camera position for larger screens
      }
    };

    handleResize(); // Set initial position
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const canvas = document.querySelector('canvas');
    
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        canvas.style.width = '100%';
        canvas.style.height = '100%';
      } else {
        canvas.style.width = '100vw';
        canvas.style.height = '100vh';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Canvas
      style={{ height: '100vh', width: '100vw' }}
      camera={{ position: cameraPosition, rotation: [0, 0, 0] }}
    >
      <Suspense fallback={null}>
        <Model />
      </Suspense>
      <OrbitControls enableZoom={false} enablePan={false} /> {/* Disable zoom and pan */}
    </Canvas>
  );
}

export default Bairon;
