import { useEffect } from 'react';
import './CustomCursor.css';

const CustomCursor = () => {
  useEffect(() => {
    const cursor = document.createElement('div');
    cursor.classList.add('custom-cursor');
    document.body.appendChild(cursor);

    let mouseX = 0, mouseY = 0;
    let cursorX = 0, cursorY = 0;
    const speed = 0.25;
    let isCursorVisible = false;

    const mouseMove = (e) => {
      mouseX = e.clientX - cursor.offsetWidth / 10000;
      mouseY = e.clientY - cursor.offsetHeight / 10000;

      if (!isCursorVisible) {
        cursor.style.opacity = 1;
        isCursorVisible = true;
      }
    };

    const mouseOverLink = () => {
      cursor.classList.remove('no-hover');
      cursor.classList.add('hover');
    };

    const mouseOutLink = () => {
      cursor.classList.remove('hover');
      cursor.classList.add('no-hover');
    };

    document.addEventListener('mousemove', mouseMove);
    document.querySelectorAll('a').forEach(link => {
      link.addEventListener('mouseover', mouseOverLink);
      link.addEventListener('mouseout', mouseOutLink);
    });

    const animate = () => {
      cursorX += (mouseX - cursorX) * speed;
      cursorY += (mouseY - cursorY) * speed;

      cursor.style.left = `${cursorX}px`;
      cursor.style.top = `${cursorY}px`;

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      document.removeEventListener('mousemove', mouseMove);
      document.querySelectorAll('a').forEach(link => {
        link.removeEventListener('mouseover', mouseOverLink);
        link.removeEventListener('mouseout', mouseOutLink);
      });
      cursor.remove();
    };
  }, []);

  return null;
};

export default CustomCursor;
